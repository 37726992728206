import React, { useEffect, Fragment } from 'react';
import './css/App.css';
import { BrowserRouter as Router, Switch, Route, useHistory } from "react-router-dom";
import { Container } from './components/styled/Syled';
import HomeView from './components/HomeView';
import { LanguageProvider } from './components/providers/LanguageProvider';
import SuccessView from './components/SuccessView';
import TransferView from './components/TransferView';
import TermsView from './components/TermsView';
import PrivacyView from './components/PrivacyView';
import ContactView from './components/ContactView';

const ScrollTop = ({children}) => {
  let history = useHistory();

  useEffect(() => {
    const unlisten = history.listen(() => {
      window.scrollTo(0, 0);
    });
    return () => {
      unlisten();
    }
  }, []);

  return <Fragment>{children}</Fragment>;
}

const App = () => {
  return <LanguageProvider>
    <Container minheight="100vh" width="12">
      <Router basename="/">
        <ScrollTop>
          <Switch>
            <Route exact={true} path="/" component={HomeView} />
            <Route exact={true} path="/success" component={SuccessView} />
            <Route exact={true} path="/transfer" component={TransferView} />
            <Route exact={true} path="/terms" component={TermsView} />
            <Route exact={true} path="/privacy" component={PrivacyView} />
            <Route exact={true} path="/contact" component={ContactView} />
          </Switch>
        </ScrollTop>
      </Router>
    </Container>
  </LanguageProvider>
}

export default App;
