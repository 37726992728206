import React from 'react';
import { ContainerFlex, TextLocalized, Button } from './styled/Syled';
import logo from './../images/logo.png'
import { useHistory } from 'react-router-dom';

export const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop)

const Header = ({ refs }) => {

    return (
        <ContainerFlex width="12" justify="center">
            <ContainerFlex width="11" sm="8" justify="space-between" align="center" pv="35">
                <ContainerFlex align="center">
                    <img alt="logo" width="64" src={logo} />
                    <ContainerFlex mh="10" fontSize="24px" fontWeight="bold" children={<TextLocalized children="msgHeaderTitle" />} />
                </ContainerFlex>
                <ContainerFlex width="12" sm="6" justify="flex-end" mv="16">
                    <Button onclick={() => scrollToRef(refs[1])} mh="16" children={<TextLocalized children="msgHeaderTabOne" />} />
                    <Button onclick={() => scrollToRef(refs[0])} mh="16" children={<TextLocalized children="msgHeaderTabTwo" />} />
                    <Button onclick={() => scrollToRef(refs[2])} mh="16" children={<TextLocalized children="msgHeaderTabThree" />} />
                </ContainerFlex>
            </ContainerFlex>
        </ContainerFlex>
    );
};

export const HeaderParent = () => {

    let history = useHistory();

    return (
        <ContainerFlex width="12" justify="center">
            <ContainerFlex width="11" sm="8" justify="flex-start" align="center" pv="35">
                <ContainerFlex align="center" onclick={() => history.push("")} cursor="pointer">
                    <img alt="logo" width="64" src={logo} />
                    <ContainerFlex mh="10" fontSize="24px" fontWeight="bold" children={<TextLocalized children="msgHeaderTitle" />} />
                </ContainerFlex>
            </ContainerFlex>
        </ContainerFlex>
    );
}

export default Header;