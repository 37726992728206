import React, { Fragment, useState, useContext } from 'react';
import { ContainerFlex, TextLocalized, Button } from './styled/Syled';

import Footer from './Footer';

import { HeaderParent } from './Header';
import { SendingMode } from './TransferView';
import { LanguageContext } from './providers/LanguageProvider';
import { notificationDefault, NotificationView } from './util/NotificationUtil';
import { HttpConnection, HttpMethod, HttpOperations } from './util/HttpConnection';


const formDefault = {
    email: "",
    name: "",
    subject: "",
    message: "",
}

const ContactView = () => {
    const [formData, setFormData] = useState(formDefault);
    const languageContext = useContext(LanguageContext)
    const [notification, setNotification] = useState(notificationDefault);
    const [sendingMode, setSendingMode] = useState(SendingMode.notsend)

    const _onchageForm = (e) => setFormData({
        ...formData,
        [e.target.name]: e.target.value
    })

    const _handleSubmit = () => {
        if (formData.email === "" || formData.name === "" || formData.subject === "" || formData.message === "") {
            setNotification({
                ...notification,
                show: true,
                message: "errorOblData"
            })
        } else {
            setSendingMode(SendingMode.sending)

            HttpConnection(HttpOperations.contact,
                (item) => {
                    console.log(item)
                    setSendingMode(SendingMode.send)
                    setNotification({
                        show: true,
                        color: "colorGreenDark",
                        message: "msgContactSuccess",
                    });

                }, (error) => {
                    console.log(error)
                    setSendingMode(SendingMode.notsend)
                    setNotification({
                        ...notification,
                        show: true,
                        message: "errorSend"
                    })
                }, HttpMethod.post, formData)
        }
    }

    return <Fragment>
        <HeaderParent />
        <ContainerFlex width="12" justify="center">
            <ContainerFlex width="10" sm="8" justify="center" align="center" >
                <ContainerFlex width="12" fontSize="44px" fontWeight="bold" pv="35" fontColor="colorGreenDark" justify="center" children={<TextLocalized children="msgFooterContact" />} />

                <ContainerFlex width="10" sm="8">
                    <input style={{ margin: '4px 0' }} type="text" name="email" placeholder={languageContext.dictionary['msgEmail']} value={formData.email} onChange={_onchageForm} />
                    <input style={{ margin: '4px 0' }} type="text" name="name" placeholder={languageContext.dictionary['msgName']} value={formData.name} onChange={_onchageForm} />
                    <input style={{ margin: '4px 0' }} type="text" name="subject" placeholder={languageContext.dictionary['msgSubject']} value={formData.subject} onChange={_onchageForm} />
                    <textarea style={{ margin: '4px 0' }} rows="10" name="message" value={formData.message} onChange={_onchageForm}></textarea>
                    <ContainerFlex pv="16" children={<TextLocalized children="msgTermsContact" />} />

                    <NotificationView noti={notification} setNoti={setNotification} />
                    {
                        sendingMode === SendingMode.sending ?
                            <Button width="12" textAlign="center" color="colorPrimary" fontColor="colorWhite" fontWeight="bold" pv="16" mv="16" children={<div className="loader">Envi...</div>} />
                            : sendingMode === SendingMode.notsend ?
                                <Button onclick={_handleSubmit} width="12" textAlign="center" color="colorPrimary" fontColor="colorWhite" fontWeight="bold" pv="16" mv="16" children={<TextLocalized children="actionSend" />} />
                                : null
                    }
                </ContainerFlex>
            </ContainerFlex>
        </ContainerFlex>
        <Footer />
    </Fragment>
};

export default ContactView;