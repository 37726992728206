import React, { Fragment, useEffect } from 'react';
import { Container, ContainerFlex, TextLocalized, Button } from './styled/Syled';

import Footer from './Footer';
import { useHistory } from 'react-router-dom';

import logo from './../images/logo.png'


const SuccessView = () => {

    let history = useHistory();

    useEffect(() => {
        //Lanzar email
    }, [])

    return <Fragment>
        <ContainerFlex width="12" justify="center">
            <ContainerFlex width="10" sm="8" justify="space-between" align="center" pv="35" minheight="100vh">
                <ContainerFlex width="12" textAlign="center" direction="column" justify="center" align="center">
                    <img alt="logo" width="128" src={logo} />
                    <Container fontSize="44px" mt="36" fontWeight="bold" fontColor="colorGreenDark" children={<TextLocalized children="msgSuccessTitle" />} />
                    <Container pr="30" mv="24" fontSize="18px" children={<TextLocalized children="msgSuccessText" />} />
                    <Button onclick={() => history.push("/")} maxwidth="fit-content" color="colorPrimary" fontColor="colorWhite" fontWeight="bold" pv="16" ph="32" mv="16" children={<TextLocalized children="actionBack" />} />
                </ContainerFlex>
            </ContainerFlex>
        </ContainerFlex>
        <Footer />
    </Fragment>
};

export default SuccessView;