
export const HttpOperations = {
    payment: "payment",
    transfer: "transfer",
    contact: "contact",
}

export const HttpMethod = {
    get: "GET",
    post: "POST",
    patch: "PATCH",
    delete: "DELETE",
    put: "PUT",
}

export const HttpConnection = async (operation, success, error, method = "GET", data = null) => {
    const request = await fetch(`${serverUrlEndpoint}${operation}`, {
        method: method,
        body: data === null ? null : JSON.stringify(data),
        headers: {
            'Content-Type': 'application/json',
        }
    })

    let item = Object()

    if (!request.ok) {
        error(request.status);
    } else {
        try {
            item = await request.json();
        } catch (error) {
            item = {};
        }
        success(item);
    }
}

const debugMode = false;
const serverUrlEndpoint = debugMode ? "http://localhost/huellas/mail/send.php/" : "https://vianneymsanchez.com/mail/send.php/";
