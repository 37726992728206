import React from 'react';
import { Card, TextLocalized, ContainerFlex } from '../styled/Syled';

export const notificationDefault = {
    show: false,
    color: "colorRed",
    message: ""
}

export const NotificationView = ({ noti }) => {
    return <ContainerFlex width="12" justify="center">
        {
            noti.show ?
                <Card px="16" fontColor="colorWhite" mt="32" textAlign="center" color={noti.color}
                    children={<TextLocalized children={noti.message} />} />
                : null
        }
    </ContainerFlex>
}
