import React, { useState, useContext } from 'react';
import { Container, ContainerFlex, TextLocalized, Button } from './styled/Syled';

import img_product from './../images/img_payment.png';

import { useHistory } from 'react-router-dom';
import { LanguageContext } from './providers/LanguageProvider';
import { HttpConnection, HttpOperations, HttpMethod } from './util/HttpConnection';
import { notificationDefault, NotificationView } from './util/NotificationUtil';

const formDefault = {
    email: "",
    country: "España",
    name: "",
    addressOne: "",
    addressTwo: "",
    postalCode: "",
    city: "",
    state: "",
}

export const SendingMode = {
    notsend: "NOT_SEND",
    sending: "SENDING",
    send: "SEND",
}

const TransferView = () => {

    const [formData, setFormData] = useState(formDefault);
    let history = useHistory();
    const languageContext = useContext(LanguageContext)
    const [notification, setNotification] = useState(notificationDefault);
    const [sendingMode, setSendingMode] = useState(SendingMode.notsend)

    const _onchageForm = (e) => setFormData({
        ...formData,
        [e.target.name]: e.target.value
    })

    const _handleSubmit = () => {
        if (formData.email === "" || formData.name === "" || formData.addressOne === "" || formData.postalCode === "" || formData.city === "" || formData.state === "") {
            setNotification({
                ...notification,
                show: true,
                message: "errorOblData"
            })
        } else {
            setSendingMode(SendingMode.sending)
            
            HttpConnection(HttpOperations.transfer,
                (item) => {
                    console.log(item)
                    setSendingMode(SendingMode.send)
                    setNotification({
                        show: true,
                        color: "colorGreenDark",
                        message: "msgContactSuccess",
                    });

                }, (error) => {
                    console.log(error)
                    setSendingMode(SendingMode.notsend)
                    setNotification({
                        ...notification,
                        show: true,
                        message: "errorSend"
                    })
                }, HttpMethod.post, formData)
        }
    }

    return <ContainerFlex width="12" justify="center">
        <ContainerFlex width="12" lg="8" justify="space-between" align="stretch" minheight="100vh">
            <ContainerFlex width="12" lg="6" pv="16" ph="32" direction="column" justify="center" minheight="100vh" >
                <Container fontSize="16px" fontWeight="500" fontColor="colorGreenDark" children={<TextLocalized children="msgTransferTitle" />} />
                <Container mv="16" fontSize="36px" fontWeight="600" children={<TextLocalized children="msgTransferPrice" />} />
                <Container fontSize="16px" fontWeight="500" fontColor="colorGreenDark" children={<TextLocalized children="msgTransferImp" />} />
                <img style={{ margin: '32px 0' }} alt="product" src={img_product} width="300" height="286" />
                <Button onclick={() => history.push("/")} maxwidth="fit-content" color="colorGreenDark" fontColor="colorWhite" fontWeight="bold" pv="16" ph="32" mv="16" children={<TextLocalized children="actionBack" />} />
            </ContainerFlex>

            <ContainerFlex shadow={true} width="12" lg="6" justify="center" align="center" pv="16" ph="32" minheight="100vh">

                <Container>
                    <Container fontSize="16px" fontWeight="500" fontColor="colorGreenDark" children={<TextLocalized children="msgTransferMsg" />} />
                    <Container width="12" mv="16" fontSize="16px" fontWeight="700" fontColor="colorGreenDark" children={<TextLocalized children="msgEmail" />} />
                    <input id="input-alone" type="text" name="email" value={formData.email} onChange={_onchageForm} />

                    <Container width="12" pv="16" fontSize="16px" fontWeight="700" fontColor="colorGreenDark" children={<TextLocalized children="msgShipping" />} />

                    <input style={{ margin: '4px 0' }} type="text" name="country" value={formData.country} onChange={_onchageForm} disabled={true} />
                    <input style={{ margin: '4px 0' }} type="text" name="name" placeholder={languageContext.dictionary['msgName']} value={formData.name} onChange={_onchageForm} />
                    <input style={{ margin: '4px 0' }} type="text" name="addressOne" placeholder={languageContext.dictionary['msgAddressOne']} value={formData.addressOne} onChange={_onchageForm} />
                    <input style={{ margin: '4px 0' }} type="text" name="addressTwo" placeholder={languageContext.dictionary['msgAddressTwo']} value={formData.addressTwo} onChange={_onchageForm} />

                    <ContainerFlex width="12">
                        <Container width="6" pr="2" children={<input style={{ margin: '4px 0' }} type="text" name="postalCode" placeholder={languageContext.dictionary['msgPostalCode']} value={formData.postalCode} onChange={_onchageForm} />} />
                        <Container width="6" pl="2" children={<input style={{ margin: '4px 0' }} type="text" name="city" placeholder={languageContext.dictionary['msgCity']} value={formData.city} onChange={_onchageForm} />} />
                    </ContainerFlex>

                    <input style={{ margin: '4px 0' }} type="text" name="state" placeholder={languageContext.dictionary['msgState']} value={formData.state} onChange={_onchageForm} />

                    <NotificationView noti={notification} setNoti={setNotification} />
                    {
                        sendingMode === SendingMode.sending ?
                            <Button width="12" textAlign="center" color="colorPrimary" fontColor="colorWhite" fontWeight="bold" pv="16" mv="16" children={<div className="loader">Envi...</div>} />
                            : sendingMode === SendingMode.notsend ?
                                <Button onclick={_handleSubmit} width="12" textAlign="center" color="colorPrimary" fontColor="colorWhite" fontWeight="bold" pv="16" mv="16" children={<TextLocalized children="actionSend" />} />
                                : null
                    }
                </Container>

            </ContainerFlex>
        </ContainerFlex>
    </ContainerFlex>
};

export default TransferView;