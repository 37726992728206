import React from 'react';
import { Container, ContainerFlex, TextLocalized, Button } from './styled/Syled';
import logo from './../images/logo.png'
import ic_secure from './../images/ic_secure.png'
import ic_visa from './../images/ic_visa.png'
import ic_mastercard from './../images/ic_mastercard.png'
import ic_american from './../images/ic_american.png'
import { useHistory } from 'react-router-dom';


const Footer = () => {

    let history = useHistory();

    return (
        <ContainerFlex width="12" justify="center">
            <Container width="12" sm="8" minheight="2px" color="colorGray" mv="64" />
            <ContainerFlex width="12" sm="8" pv="35" mb="64" justify="space-between">
                <ContainerFlex align="flex-start">
                    <ContainerFlex align="center" mr="64">
                        <img alt="logo" width="64" src={logo} />
                        <ContainerFlex mh="10" fontSize="24px" fontWeight="bold" children={<TextLocalized children="msgHeaderTitle" />} />
                    </ContainerFlex>
                    <Container mt="4">
                        <Button onclick={() => history.push("/terms")}  ph="16" mh="16" children={<TextLocalized children="msgFooterTerms" />} />
                        <Button onclick={() => history.push("/privacy")} ph="16" mh="16" children={<TextLocalized children="msgFooterPrivacy" />} />
                        <Button onclick={() => history.push("/contact")} ph="16" mh="16" children={<TextLocalized children="msgFooterContact" />} />
                    </Container>
                </ContainerFlex>

                <ContainerFlex sm="4" justify="flex-end">
                    <img alt="secure" width="100" src={ic_secure} />
                    <ContainerFlex width="12" justify="flex-end" align="center" mv="16">
                        <img alt="visa" style={{margin: '0px 16px'}} width="80" src={ic_visa} />
                        <img alt="mastercard" style={{margin: '0px 16px'}} width="80" src={ic_mastercard} />
                        <img alt="americanexpress" style={{margin: '0px 16px'}} width="80" src={ic_american} />
                    </ContainerFlex>

                </ContainerFlex>

            </ContainerFlex>
        </ContainerFlex>
    );
};

export default Footer;