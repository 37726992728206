import React, { Fragment } from 'react';
import { Container, ContainerFlex, TextLocalized } from './styled/Syled';

import Footer from './Footer';
import { HeaderParent } from './Header';

const TermsView = () => {

    return <Fragment>
        <HeaderParent />
        <ContainerFlex width="12" justify="center">
            <ContainerFlex width="10" sm="8" justify="space-between" align="center" pv="35" minheight="100vh">
            <ContainerFlex width="12" fontSize="44px" fontWeight="bold" pv="35" fontColor="colorGreenDark" justify="center" children={<TextLocalized children="msgFooterTerms" />} />
                <Container children={<TextLocalized children='msgTermsText'/>} />
            </ContainerFlex>
        </ContainerFlex>
        <Footer />
    </Fragment>
};

export default TermsView;