import React, { Fragment, useState, useEffect, useRef } from 'react';
import { Container, ContainerFlex, TextLocalized, Button } from './styled/Syled';

import img_one from './../images/img_sec_one.png';
import img_two from './../images/img_sec_two.png';
import ic_transfer from './../images/ic_transfer.png';
import ic_card from './../images/ic_card.png';



import bc_green from './../images/bc_green.png';

import { loadStripe } from '@stripe/stripe-js';
import Header, { scrollToRef } from './Header';
import Footer from './Footer';
import { useHistory } from 'react-router-dom';
const stripePromise = loadStripe('pk_live_51HCmO3KycEsVwUAkkCE2im2Br9b7w5mgQ1QAwkjzKBCNU9EV1JGlvC9itVSoeuq4ILtR0MmKEV8F6xmwDEWNI6CW00FfadIuWI');
//const stripePromise = loadStripe('pk_test_51HCmO3KycEsVwUAkYxvoxzq0ne9ioJ6tlDpimKiO5kMcJsTph7RkSN8NhjJVRlYYR0yNBxY3c7IXZZHdzILy7doh00jlE72bjo');

const BuyMode = {
    modeStripe: "MODE_STRIPE",
    modeTransf: "MODE_TRANSF",
}

const HomeView = () => {

    const refSecCol = useRef(null)
    const refSeAut = useRef(null)
    const refSeBuy = useRef(null)
    const [refs, setRefs] = useState([])

    const [mode, setMode] = useState("")

    let history = useHistory();

    useEffect(() => {
        setRefs([refSecCol, refSeAut, refSeBuy])
    }, [])

    const _handleClick = async (event) => {
        // When the customer clicks on the button, redirect them to Checkout.
        const stripe = await stripePromise;
        const { error } = await stripe.redirectToCheckout({
            lineItems: [{
                price: 'price_1HLrYkKycEsVwUAkortlisC8', // Replace with the ID of your price
                //price: 'price_1HLoKGKycEsVwUAkOO4ZGa37',
                quantity: 1,
            }],
            mode: 'payment',
            successUrl: 'http://vianneymsanchez.com/success',
            cancelUrl: 'http://vianneymsanchez.com',
            billingAddressCollection: 'required',
            shippingAddressCollection: {
                allowedCountries: ['ES'],
            },

        });
        // If `redirectToCheckout` fails due to a browser or network
        // error, display the localized error message to your customer
        // using `error.message`.
    };

    const _checkPermission = () => {
        if (mode === BuyMode.modeStripe) {
            _handleClick();
        } else {
            history.push('transfer')
        }
    }

    return <Fragment>
        <Header refs={refs} />
        <ContainerFlex width="12" justify="center">
            <ContainerFlex width="10" sm="8" justify="space-between" align="center" pv="35">
                <Container width="12" sm="5">
                    <Container fontSize="44px" fontWeight="bold" fontColor="colorGreenDark" children={<TextLocalized children="msgSectionOneTitle" />} />
                    <Container pr="30" mv="24" fontSize="18px" children={<TextLocalized children="msgSectionOneText" />} />
                    <Container fontSize="36px" fontWeight="bold" fontColor="colorGreenDark" children={<TextLocalized children="msgPrice" />} />
                    <Button onclick={() => scrollToRef(refs[2])} maxwidth="fit-content" color="colorPrimary" fontColor="colorWhite" fontWeight="bold" pv="16" ph="32" mv="16" children={<TextLocalized children="actionComprar" />} />
                    <Container fontSize="14px" mt="32" fontColor="colorBlack" children={<TextLocalized children="msgEnvio" />} />
                </Container>
                <Container width="12" sm="6" mv="32">
                    <img width="120%" alt="section-one" src={img_one} />
                </Container>
            </ContainerFlex>

            <ContainerFlex myRef={refs[0]} id="sec-col" width="12" justify="center" textAlign="center" mv="30">
                <Container fontSize="44px" fontWeight="bold" fontColor="colorGreenDark" children={<TextLocalized children="msgSectionTwoTitle" />} />
                <ContainerFlex width="12" justify="center" image={bc_green} mv="32">
                    <ContainerFlex myRef={refs[2]} width="12" sm="8" textAlign="start" justify="center">
                        <Container width="10" sm="3" ph="16" mv="16" children={<TextLocalized children="msgSectionTwoColOne" />} />
                        <Container width="10" sm="3" ph="16" mv="16" children={<TextLocalized children="msgSectionTwoColTwo" />} />
                        <Container width="10" sm="3" ph="16" mv="16" children={<TextLocalized children="msgSectionTwoColThree" />} />
                        <Container width="10" sm="3" ph="16" mv="16" children={<TextLocalized children="msgSectionTwoColFour" />} />
                        <ContainerFlex  width="12" justify="center" align="stretch" mv="64">
                            <Button opacity={mode === BuyMode.modeStripe || mode === "" ? 1 : 0.6} onclick={() => setMode(BuyMode.modeStripe)} maxwidth="fit-content" color="colorPrimary" fontColor="colorWhite" fontWeight="bold" pv="16" ph="32" mh="8" children={<img height="30" alt="transferencia" src={ic_card} />} />
                            <Button opacity={mode === BuyMode.modeTransf || mode === "" ? 1 : 0.6} flex={true} justify="center" align="center" onclick={() => setMode(BuyMode.modeTransf)} maxwidth="fit-content" color="colorPurple" fontColor="colorWhite" fontWeight="bold" pv="10" ph="32" mh="8" children={<img height="30" alt="transferencia" src={ic_transfer} />} />
                        </ContainerFlex>
                        {
                            mode !== "" ?
                                <Fragment>
                                    <ContainerFlex width="12" mt="-16" textAlign="center" justify="center" children={<TextLocalized children="msgAcceptTerms" />} />
                                    <Button onclick={_checkPermission} maxwidth="fit-content" color="colorPrimary" fontColor="colorWhite" fontWeight="bold" pv="16" ph="32" mv="32" mh="8" children={<TextLocalized children="msgAcceptContinue" />} />
                                </Fragment>
                                : null
                        }
                    </ContainerFlex>
                </ContainerFlex>
            </ContainerFlex>

            <ContainerFlex myRef={refs[1]} id="sec-aut" width="12" sm="8" justify="center" align="center" pv="35">
                <Container width="10" sm="6">
                    <Container children={<TextLocalized children="msgSectionThreeSubtitle" />} />
                    <Container fontSize="44px" fontWeight="bold" fontColor="colorGreenDark" children={<TextLocalized children="msgSectionThreeTitle" />} />
                    <Container pr="30" mv="24" fontSize="18px" children={<TextLocalized children="msgSectionThreeText" />} />
                </Container>
                <Container width="12" sm="6">
                    <img width="100%" alt="section-one" src={img_two} />
                </Container>
            </ContainerFlex>

        </ContainerFlex>
        <Footer />
    </Fragment>
};

export default HomeView;